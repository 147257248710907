(function () {
  if (window._fitAnalytics) return;

  const PLPCategoryGenderMapping = {
    1010021: 'm',
    1040021: 'w',
    1000001: 'w'
  };

  const getPLPCategoryId = () => {
    return window.trackingJSONObject?.pageData?.categoryData?.category_id?.split('_').pop();
  };

  const isPLP = () => {
    return !!document.querySelector('.fit-finder-plp');
  };

  const getBackupLanguage = () => {
    const backupLang = window.trackingJSONObject?.genericData?.cookieData?.languageSelected || '';
    if (isPLP() && backupLang == 'zh' && getBackupCountry().toLowerCase() == 'hk') {
      return 'zf';
    }
    return backupLang;
  };

  const getBackupCountry = () =>
    window.trackingJSONObject?.genericData?.cookieData?.countrySelected || '';

  window._fitAnalytics = function (imports) {
    const { _, $, Integration, AddOn } = imports;

    class BtnStyle extends AddOn {
      onProductLoaded() {
        $('[data-class-name = "sizechart-flyout-container"]').css({
          'white-space': 'nowrap'
        });
        $('.ms-size-advisor').css({
          position: 'relative',
          'margin-top': '22px',
          'align-self': 'auto'
        });
        if (Integration.isMobile()) {
          $('.fitanalytics__button-text b').css({
            display: 'block'
          });
        }
      }
    }

    let btnStyle = new BtnStyle();

    const integration = new Integration({
      addOns: [btnStyle],
      shop: {
        node: window,
        prefix: 'ms',
        language: {
          path: 'fitAnalyticsData.shopLanguage',
          process: (lang) => lang || getBackupLanguage()
        },
        country: {
          path: 'fitAnalyticsData.shopCountry',
          process: (country) => country || getBackupCountry()
        },
        userId: {
          path: 'fitAnalyticsData.userId'
        },
        rootDomain: {
          node: window,
          path: 'location.hostname',
          process: /(marksandspencer\.[a-z\.]+$)/
        },
        shopSessionId: {
          cookie: '_gid'
        },
        gender: () => PLPCategoryGenderMapping[getPLPCategoryId()],
        isReady: () => {
          if (!!document.querySelector('.custom__container.pdp')) {
            return Boolean(window.fitAnalyticsData);
          }
          return (
            !!document.querySelector('.filters-count-result') &&
            !!PLPCategoryGenderMapping[getPLPCategoryId()]
          );
        }
      },
      fitFinderOnPLP: {
        node: 'body',
        button: {
          anchor: '.fit-finder-plp',
          anchorPlacement: 'append',
          style: {
            button: {
              position: 'relative',
              'max-width': '100%',
              margin: '20px auto 0 auto',
              display: PLPCategoryGenderMapping[getPLPCategoryId()] ? 'flex' : 'none',
              'justify-content': 'center'
            },
            image: {
              'margin-bottom': '5px',
              'margin-left': '5px',
              'vertical-align': 'middle'
            },
            text: {
              'font-family': 'ms-london-w-regular',
              'font-weight': 'bold',
              'vertical-align': 'middle',
              'font-size': '14px',
              color: '#000',
              'margin-top': '0',
              'text-decoration': 'underline',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
              overflow: 'hidden'
            }
          },
          text: {
            en: {
              formal: {
                default: 'Create your size profile',
                active: 'Create your size profile'
              }
            },
            de: {
              formal: {
                default: 'Erstellen Sie Ihr Größenprofil',
                active: 'Erstellen Sie Ihr Größenprofil'
              }
            },
            es: {
              formal: {
                default: 'Crea tu perfil de tallas',
                active: 'Crea tu perfil de tallas'
              }
            },
            fr: {
              formal: {
                default: 'Créez votre profil de taille',
                active: 'Créez votre profil de taille'
              }
            },
            el: {
              formal: {
                default: 'Δημιουργία προφίλ μεγέθους',
                active: 'Δημιουργία προφίλ μεγέθους'
              }
            },
            nl: {
              formal: {
                default: 'Creëer uw maatprofiel',
                active: 'Creëer uw maatprofiel'
              }
            },
            cs: {
              formal: {
                default: 'Vytvořte si profil velikostí',
                active: 'Vytvořte si profil velikostí'
              }
            },
            id: {
              formal: {
                default: 'Buat profil ukuran Anda',
                active: 'Buat profil ukuran Anda'
              }
            },
            in: {
              formal: {
                default: 'Buat profil ukuran Anda',
                active: 'Buat profil ukuran Anda'
              }
            },
            he: {
              formal: {
                default: 'צרו את פרופיל המידות שלכם',
                active: 'צרו את פרופיל המידות שלכם'
              }
            },
            iw: {
              formal: {
                default: 'צרו את פרופיל המידות שלכם',
                active: 'צרו את פרופיל המידות שלכם'
              }
            },
            ar: {
              formal: {
                default: 'أنشأ ملف مقاسك',
                active: 'أنشأ ملف مقاسك'
              }
            },
            zh: {
              formal: {
                default: '创建您的尺码档案',
                active: '创建您的尺码档案'
              }
            },
            zf: {
              formal: {
                default: '創建您的尺碼檔案',
                active: '創建您的尺碼檔案'
              }
            }
          }
        }
      },
      product: {
        container: '.custom__container.pdp',

        hasManufacturedSizes: true,

        serials: {
          node: window,
          path: 'fitAnalyticsData.allProductIds',
          process: (serial) => serial?.split('_').shift()
        },

        currentSerial: {
          node: window,
          path: 'fitAnalyticsData.currentProductId',
          process: (serial) => serial?.split('_').shift()
        },

        thumbnail: {
          node: window,
          path: 'fitAnalyticsData.mainThumbnail'
        },

        addToCart: (serial, size) => {
          const addToCartFunction = _.lookup(
            window,
            'fitAnalyticsData.operations.addToCart',
            () => {}
          );
          const sizes = _.lookup(window, 'fitAnalyticsData.sizes', []);
          const baseSizeValue = sizes.find(
            (sizeObj) => sizeObj.value?.replaceAll('&nbsp;', ' ') === size
          )?.baseValue;
          if (typeof addToCartFunction === 'function' && baseSizeValue) {
            return addToCartFunction(baseSizeValue);
          }
          return null;
        },

        sizes: {
          node: window,
          items: {
            path: 'fitAnalyticsData.sizes'
          },
          code: {
            path: 'value',
            process: (val) => val?.replaceAll('&nbsp;', ' ')
          },
          isAvailable: {
            path: 'isAvailable'
          }
        }
      },

      button: {
        anchor: '.fita-size-advisor .fita-advisor-link',
        anchorPlacement: 'append',
        style: {
          button: {
            margin: '0',
            display: 'inline-grid',
            'white-space': 'break-spaces'
          },
          text: {
            'font-size': '14px',
            'margin-bottom': '10px',
            color: '#000',
            'text-decoration': 'underline'
          },
          image: {
            height: '20px',
            'margin-left': '-5px'
          }
        }
      },

      sizeChartButton: {
        elem: '.ms-size-advisor',
        shouldHide: false
      }
    });

    window._fitAnalytics.integration = integration;
    integration.start();
  };

  function init() {
    const scriptElem = document.createElement('script');
    scriptElem.setAttribute('src', '//widget.fitanalytics.com/widget_pdp.js');
    scriptElem.setAttribute('type', 'text/javascript');
    document.body.appendChild(scriptElem);
  }
  // Under some circumstances, our script may be loaded before the document is ready
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      init();
    });
  }
})();
